import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

function toggleDetails(button) {
  const details = button.nextElementSibling;
  if (details.style.display === "none" || details.style.display === "") {
    details.style.display = "block";
    button.textContent = "Hide Details";
  } else {
    details.style.display = "none";
    button.textContent = "Details";
  }
}

// Make sure the function is globally available
window.toggleDetails = toggleDetails;

// Add hover functionality to show/hide captions
const projectWrappers = document.querySelectorAll('.project-wrapper');

// Loop through each project wrapper
projectWrappers.forEach((wrapper) => {
  const caption = wrapper.querySelector('.caption'); // Get the caption inside the wrapper

  // Show caption on hover
  wrapper.addEventListener('mouseenter', () => {
    caption.style.opacity = '1'; // Make the caption visible
  });

  // Hide caption when hover ends
  wrapper.addEventListener('mouseleave', () => {
    caption.style.opacity = '0'; // Hide the caption
  });
});
